import { createContext, useState, useEffect, FC, useContext } from "react";
import { auth, firestore } from "firebase-internal";
import LogRocket from "logrocket";
import { signInAnonymously } from "firebase/auth";
import { useUser } from "auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Loader } from "Components";

interface AuthContextProps {
  isArtist: boolean;
}

let isRequestingLogin = false;

export const AuthContext = createContext<AuthContextProps>(undefined);

export const AuthProvider: FC = ({ children }) => {
  const { user } = useUser();
  const [isArtist, setIsArtist] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  if (user === null && !isRequestingLogin) {
    isRequestingLogin = true;
    try {
      signInAnonymously(auth).finally(() => {
        isRequestingLogin = false;
      });
    } catch (error) {
      isRequestingLogin = false;
      console.error(error);
    }
  }

  useEffect(() => {
    if (user?.uid) {
      if (user.isAnonymous) {
        setIsArtist(false);
        setLoading(false);
        return;
      }
      getDocs(
        query(
          collection(firestore, "setlive_events"),
          where(`memberUids`, "array-contains", user?.uid)
        )
      )
        .then((response) => {
          setIsArtist(response.size > 0);
          setLoading(false);
        })
        .catch(() => {
          setIsArtist(false);
          setLoading(false);
        });
    }
  }, [user?.uid]);

  useEffect(() => {
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
      phone: user?.phoneNumber,
    });
  }, [user]);

  const value: AuthContextProps = {
    isArtist: isArtist ?? false,
  };

  if (loading) {
    return <Loader />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
