import styled from "styled-components";

interface FlexBaseProps {
  flex?: string;
  column?: boolean;
  row?: boolean;
}

interface FlexDirectionProps extends FlexBaseProps {
  xStart?: boolean;
  yStart?: boolean;
  xCenter?: boolean;
  yCenter?: boolean;
  xEnd?: boolean;
  yEnd?: boolean;
}

export const Flex = styled.div<FlexBaseProps>`
  box-sizing: border-box;
  display: flex;
  ${(props) => props.column && "flex-direction: column;"};
  ${(props) => props.row && "flex-direction: row;"};
  ${(props) => props.flex && `flex: ${props.flex};`};
`;

export const FlexColumn = styled.div<FlexDirectionProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${(props) => props.xStart && "align-items: flex-start;"};
  ${(props) => props.xCenter && "align-items: center;"};
  ${(props) => props.xEnd && "align-items: flex-end;"};
  ${(props) => props.yStart && "justify-content: flex-start;"};
  ${(props) => props.yCenter && "justify-content: center;"};
  ${(props) => props.yEnd && "justify-content: flex-end;"};
  ${(props) => props.flex && `flex: ${props.flex}`};
`;

export const FlexRow = styled.div<FlexDirectionProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  ${(props) => props.xStart && "justify-content: flex-start;"};
  ${(props) => props.xCenter && "justify-content: center;"};
  ${(props) => props.xEnd && "justify-content: flex-end;"};
  ${(props) => props.yStart && "align-items: flex-start;"};
  ${(props) => props.yCenter && "align-items: center;"};
  ${(props) => props.yEnd && "align-items: flex-end;"};
  ${(props) => props.flex && `flex: ${props.flex};`};
`;
