import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import {
  useLocationContext,
  useBrandingContext,
  useAuthContext,
  Coordinates,
} from "Context";
import { useAnalytics } from "Hooks";
import {
  Footer as FooterBase,
  FooterCopyright,
  FooterLanguageContainer,
  FooterLink,
  Button,
  Autocomplete,
} from "Components";
import {
  FlexColumn,
  FlexRow,
  H1,
  P,
  Footnote,
  Icon,
  Link,
  H4,
  Subheader,
} from "notes";
import { useHistory } from "react-router-dom";
import { useUser } from "auth";
import { Client } from "typesense";

import { getNodes, getNode } from "Context/SearchContext";
import { DateTime } from "luxon";
import {
  SearchParams,
  SearchResponseHit,
} from "typesense/lib/Typesense/Documents";
import { DividerWithLabel } from "./Fan/Components";
import { IconLocation, IconSearch } from "Images/Icons";
import { MatchedEventDetail } from "./Fan/MultipleShows";

const client = new Client({
  nodes: getNodes(process.env.REACT_APP_SEARCH_SERVER),
  nearestNode: getNode(process.env.REACT_APP_SEARCH_SERVER_NEAREST),
  apiKey: process.env.REACT_APP_TYPESENSE_KEY,
  logLevel: process.env.REACT_APP_ENV === "production" ? "silent" : "debug",
});

interface CheckNearbyEvents {
  allEvents: MatchedEventDetail[];
  nearbyEvents?: MatchedEventDetail[];
  hasMultipleNearbyEvents: boolean;
}

const checkForNearbyEvents = (
  hits: SearchResponseHit<MatchedEventDetail>[],
  searchDistanceMeters: number
): CheckNearbyEvents => {
  const allEvents = hits?.map((hit) => {
    const document = hit.document;
    const distance = hit["geo_distance_meters"];
    return {
      ...document,
      distance_meters: distance?.coordinates,
      isNearby: distance?.coordinates <= searchDistanceMeters,
    };
  });

  const nearbyEvents = allEvents?.filter(({ isNearby }) => isNearby);
  const hasMultipleNearbyEvents = nearbyEvents?.length > 1;

  return { allEvents, nearbyEvents, hasMultipleNearbyEvents };
};

export const Landing = () => {
  const { logClick } = useAnalytics();
  const { claims } = useUser();
  const { isAdmin } = claims || { isAdmin: false };
  const { isArtist } = useAuthContext();
  const { logoLarge, logos, brand, brandingId, landing } = useBrandingContext();
  const history = useHistory();
  const {
    hasLocationError,
    locationErrorMessage,
    handleLocationRequest,
    location,
    ipLocation,
    locationLoading,
    allowLocationSearch,
    setAllowLocationSearch,
  } = useLocationContext();
  const [preloadedEvents, setPreloadedEvents] =
    useState<MatchedEventDetail[]>();
  const searchDistanceMeters = 200;

  const queryEvents = async (
    queryParams: SearchParams,
    radiusLimitCoords?: Coordinates
  ) => {
    let filter_by = `endedAt:=0 && deletedAt:=0`.concat(
      queryParams?.filter_by ||
        ` && startsAt:<=${DateTime.utc()
          .plus({ hours: 12 })
          .toMillis()} && startsAt:>=${DateTime.utc()
          .minus({ hours: 5 })
          .toMillis()}`
    );
    if (radiusLimitCoords) {
      filter_by = filter_by.concat(
        ` && coordinates:(${radiusLimitCoords?.latitude}, ${radiusLimitCoords?.longitude}, 200 mi)`
      );
    }
    if (brandingId) {
      filter_by = filter_by.concat(` && brandingId:[${brandingId}]`);
    }
    try {
      const result = await client
        .collections<MatchedEventDetail>("setlive_events")
        .documents()
        .search({
          limit_hits: 4,
          page: 1,
          per_page: 4,
          ...queryParams,
          filter_by,
        });

      if (result?.hits?.length) {
        return result.hits;
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Redirect to found show(s)
  useEffect(() => {
    (async () => {
      if (location && allowLocationSearch && !hasLocationError) {
        const res = await queryEvents(
          {
            q: "*",
            query_by: "artistName",
            sort_by: `coordinates(${location.latitude}, ${location.longitude}):asc,startsAt:asc`,
          },
          location
        );

        if (!!res?.length) {
          const { allEvents, nearbyEvents, hasMultipleNearbyEvents } =
            checkForNearbyEvents(res, searchDistanceMeters);
          if (hasMultipleNearbyEvents) {
            history.push("/select", { matchedEvents: nearbyEvents });
          } else if (!!allEvents?.length) {
            history.push(`/event/${allEvents[0]?.id}`, {
              showConfirm: true,
            });
          }
        }
      }
    })();
    return () => {};
  }, [location]);

  // Prepopulate dropdown
  useEffect(() => {
    (async () => {
      if (ipLocation || location) {
        const coords = location ?? ipLocation;
        const res = await queryEvents(
          {
            q: "*",
            query_by: "artistName",
            sort_by: `coordinates(${coords.latitude}, ${coords.longitude}):asc,startsAt:asc`,
          },
          coords
        );

        if (!!res?.length) {
          const { allEvents, nearbyEvents, hasMultipleNearbyEvents } =
            checkForNearbyEvents(res, searchDistanceMeters);
          const events = hasMultipleNearbyEvents
            ? nearbyEvents
            : allEvents?.splice(0, 2);
          setPreloadedEvents(events);
        }
      }
    })();
    return () => {};
  }, [ipLocation, location]);

  useEffect(() => {
    if (isArtist || isAdmin) {
      history.push("/artist");
    }
  }, [isArtist, isAdmin]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const onFindShowClickHandler = () => {
    logClick({
      action: "find_a_show",
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onArtistClickHandler = (path: string) => {
    logClick({
      action: "internal_link_block",
      label: path,
    });
    history.push(path);
  };

  const onExternalLinkClickHandler = (path: string) => {
    logClick({
      action: "external_link_block",
      label: path,
    });
    window.open(path, "_blank");
  };

  const contentBlocks = landing?.blocks?.map((block) => ({
    ...block,
    onClick:
      block.link === "scroll-top"
        ? onFindShowClickHandler
        : block.link?.startsWith("/")
        ? () => onArtistClickHandler(block.link)
        : () => onExternalLinkClickHandler(block.link),
  }));

  const search = async (searchTerm: string) => {
    const queriedEvents = await queryEvents({
      q: searchTerm,
      query_by: "artistName, venue",
      limit_hits: 3,
      per_page: 3,
      sort_by: "startsAt:asc",
      filter_by: `&& startsAt:<=${DateTime.utc()
        .plus({ hours: 12 })
        .toMillis()} && startsAt:>=${DateTime.utc()
        .minus({ hours: 12 })
        .toMillis()}`,
    });
    const results = queriedEvents?.map((e) => e.document) || [];
    return results;
  };

  const onEventSelectClickHandler = (result) => {
    logClick({
      action: "select_show_via_search",
      label: result?.artistName,
      eventId: result?.id,
    });
    history.push(`/event/${result?.id}`, { state: { showConfirm: true } });
  };

  const blocks = contentBlocks;
  const isLocationLoading = locationLoading && allowLocationSearch;
  const logo = logoLarge ? logoLarge : logos?.landing?.path;
  return (
    <Wrapper flex="1 0 100%">
      <Container>
        <Header yCenter>
          <LogoImage src={logo} alt={brand} />
          <Icon
            indicator
            name="Questions"
            onClick={() => history.push("/contact")}
            style={{ cursor: "pointer" }}
          />
        </Header>
        <ShowSearch flex="1 1 100%">
          <H1>{landing?.title}</H1>
          <H4>Find your show here...</H4>
          <Autocomplete
            leftIcon={<IconSearch />}
            openOnFocus
            options={preloadedEvents}
            onChange={() => {}}
            search={search}
            placeholder="Search by artist name or venue..."
            Result={({ result }, index) => {
              const date =
                result?.startsAt > 1 && DateTime.fromMillis(result?.startsAt);
              return !!result.text ? (
                <Item key={index}>
                  <P style={{ fontWeight: 400 }}>No matches found...</P>
                </Item>
              ) : (
                <Item
                  key={index}
                  onMouseDown={() => onEventSelectClickHandler(result)}
                >
                  <P>{result?.artistName}</P>
                  <Footnote>
                    <span>
                      {result?.venue} • {date?.toFormat("LLL dd")} •{" "}
                      {date?.toFormat("h:mm")}{" "}
                      {date?.toFormat("a")?.toLowerCase()}{" "}
                      {date?.toFormat("ZZZZ")}
                    </span>
                  </Footnote>
                  <Footnote>{result?.address}</Footnote>
                </Item>
              );
            }}
          />
          <DividerWithLabel>Or</DividerWithLabel>
          <FlexColumn style={{ position: "relative" }}>
            <ButtonLocation
              onClick={() => {
                if (!allowLocationSearch) {
                  setAllowLocationSearch(true);
                }
                handleLocationRequest();
              }}
              disabled={isLocationLoading}
              style={{ opacity: isLocationLoading ? 0.6 : 1 }}
            >
              {isLocationLoading ? (
                <>
                  <LoaderInline />
                  {landing?.buttonLoading}
                </>
              ) : (
                <>
                  <IconLocation />
                  {landing?.button}
                </>
              )}
            </ButtonLocation>
          </FlexColumn>
          {hasLocationError && (
            <LocationErrorMessage>
              <Footnote>
                {locationErrorMessage}
                <br />
                You can also use the search field above.
              </Footnote>
            </LocationErrorMessage>
          )}
        </ShowSearch>
        {blocks?.map(({ title, body, image, button, onClick }, index) => (
          <ImageLockup xEnd={index === 0} key={index}>
            <Image imageSrc={image} />
            <Card>
              <H1>{title}</H1>
              <P>{body}</P>
              <BlockButton onClick={onClick}>{button}</BlockButton>
            </Card>
          </ImageLockup>
        ))}
        <Footer />
      </Container>
    </Wrapper>
  );
};

const BlockButton = styled(Button)`
  margin-top: 24px;
  min-width: initial;

  ${(props) =>
    !!props.theme.branding.landing.colors?.blocks?.button &&
    css`
      background-color: ${extractStyles(props, "blocks")?.button};
      color: ${extractStyles(props, "blocks")?.buttonText ??
      props.theme.branding.colors.actionLabel};
      border: ${props.theme.branding.landing.colors?.blocks?.buttonBorder
        ? `1px solid ${extractStyles(props, "blocks")?.buttonBorder}`
        : "none"};
      &:not(:disabled):hover,
      &:not(:disabled):focus {
        border: ${props.theme.branding.landing.colors?.blocks?.buttonBorder
          ? `1px solid ${extractStyles(props, "blocks")?.buttonBorder}`
          : "none"};
        background-color: ${extractStyles(props, "blocks")?.button};
        color: ${extractStyles(props, "blocks")?.buttonText ??
        props.theme.branding.colors.actionLabel};
      }
    `};
`;

export const extractStyles = (props: any, key: string) =>
  props.theme.branding.landing?.colors[key];

const Footer = styled(FooterBase)`
  ${FooterLanguageContainer} {
    display: none;
  }
  ${FooterLink} {
    color: ${(props) => props.theme.branding.landing.colors.footerLinks};
  }
  ${FooterCopyright} {
    color: ${(props) => props.theme.branding.landing.colors.copyright};
  }
`;

const Wrapper = styled(FlexColumn)`
  background: ${(props) => extractStyles(props, "page")};
`;

const Container = styled(FlexColumn)`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const LogoImage = styled.img`
  width: ${(props) => props.theme.branding.logos?.landing?.width};
  object-fit: contain;
`;

const Header = styled(FlexRow)`
  color: #ffffff;
  justify-content: space-between;
  padding: 24px;
  svg {
    path {
      fill: ${(props) => props.theme.branding.landing.colors.headerActions};
    }
  }
`;

const ShowSearch = styled(FlexColumn)`
  padding: 16px 24px 40px;
  position: relative;
  width: 100%;
  ${H1} {
    color: ${(props) => extractStyles(props, "title")};
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 40px;
    text-transform: none;
  }
  ${H4} {
    color: #ffffff;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    margin-top: 11px;
    margin-bottom: 32px;
  }
  ${Subheader} {
    font-family: ${(props) => props.theme.branding.headerFont};
    color: #f7fafc;
    font-size: 20px;
    line-height: 24px;
    margin-top: 24px;
    text-transform: uppercase;
  }

  & > div:first-of-type {
    background: transparent;
    border-radius: initial;
  }
`;

const LocationErrorMessage = styled(FlexRow)`
  margin-top: 12px;
  margin-bottom: -27px;
  justify-content: center;
  ${Footnote} {
    color: #ffffffd9;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    line-height: 15px;
    text-align: center;
  }
  ${Link} {
    margin-left: 6px;
    color: #ffffff;
    font-size: 12px;
    line-height: 15px;
    text-decoration: underline;
    svg {
      margin-right: 2px;
      width: 12px;
      height: 12px;
    }
  }
`;

const Image = styled(FlexColumn)`
  background: url(${(props) => props.imageSrc});
  background-repeat: none;
  background-size: cover;
  background-position: center center;
  padding-top: 66.49%;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    border-radius: 6px;
  }
`;

const Card = styled(FlexColumn)`
  background-color: ${(props) => extractStyles(props, "blocks")?.background};
  border-radius: 0 32px 0 32px;
  box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.5);
  margin-top: -48px;
  padding: 20px 24px 24px;
  width: 290px;
  ${H1} {
    color: ${(props) => extractStyles(props, "blocks")?.title};
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 4px;
  }
  ${P} {
    color: ${(props) => extractStyles(props, "blocks")?.body};
  }
`;

const ImageLockup = styled(FlexColumn)`
  margin: 16px 0 40px;
  width: 100%;
  & + & {
    ${Card} {
      border-radius: 32px 0 32px 0;
      box-shadow: -4px 8px 16px 0 rgba(0, 0, 0, 0.5);
    }
  }
`;

const Item = styled(FlexColumn)`
  background-color: ${(props) =>
    props.selected ? props.theme.colors.selectItemHover : "#FFFFFF"};
  border-bottom: 1px solid ${(props) => props.theme.colors.selectItemDivider};
  color: ${(props) => props.theme.colors.inputText};
  cursor: pointer;
  position: relative;
  padding: 12px;
  ${P} {
    font-weight: 600;
  }
  ${Footnote} {
    color: #4c4c4c;
    font-style: normal;
    line-height: 16px;
  }
  ${Footnote} + ${Footnote} {
    color: #666666;
    font-style: italic;
    font-weight: 500;
  }
  span {
    font-weight: 600;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.selectItemHover};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const ButtonLocation = styled.button`
  background-color: ${(props) => extractStyles(props, "button")};
  border: ${(props) => extractStyles(props, "buttonBorder")};
  border-radius: 6px;
  color: ${(props) => extractStyles(props, "buttonText")};
  width: 100%;
  height: 44px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  text-align: center;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${(props) => props.theme.typography.button};
  font-size: 16px;
  transition: all 100ms ease-out;
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
    transition: all 100ms ease-in;
  }
  ${(props) =>
    props.disabled &&
    css`
      ${(props) => props.theme.button.disabled};
      ${(props) => props.theme.button.primary.disabled};
      /* background-color: #e6e9eba0; */
      color: #666666;
    `};

  svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
`;

const LoaderInline = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
  margin-right: 12px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid ${(props) => props.theme.palette.gray.lightest};
  border-right: 2px solid ${(props) => props.theme.palette.gray.lightest};
  border-bottom: 2px solid ${(props) => props.theme.palette.gray.lightest};
  border-left: 2px solid ${(props) => props.theme.palette.gray.lighter};
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default Landing;
