import styled from "styled-components";

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.38);
`;

export const DividerWithLabel = ({
  children,
  ...props
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Container {...props}>
      <DividerLeft />
      {children && (
        <LabelContainer>
          <Subtitle1>{children}</Subtitle1>
        </LabelContainer>
      )}
      <DividerRight />
    </Container>
  );
};

const DividerLeft = styled(Divider)``;
const DividerRight = styled(Divider)``;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 100%;
  margin: 16px 0;
`;

const Subtitle1 = styled("h5")`
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;

const LabelContainer = styled.div`
  padding: 0 12px;
`;
