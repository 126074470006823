import styled, { css } from "styled-components";
import {
  Footer as FooterBase,
  Header,
  Button,
  ButtonSecondary,
  Link,
} from "Components";
import { FlexColumn, H3, H4, Small, FlexRow } from "notes";
import { getDateOrdinal } from "Utils";
import { useBrandingContext, useLocationContext } from "Context";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useAnalytics } from "Hooks";
import { DateTime } from "luxon";
import { IconCalendar, IconLocation } from "Images/Icons";

export interface MatchedEventDetail {
  address: string;
  artistName: string;
  id: string;
  image: string;
  startsAt: number;
  venue: string;
  distance_meters?: number;
  isNearby?: boolean;
}

export const MultipleShows = () => {
  const { setAllowLocationSearch } = useLocationContext();
  const { state } = useLocation();
  const history = useHistory();
  const {
    lobby,
    footer: { termsOfUseUrl },
  } = useBrandingContext();
  const { logClick } = useAnalytics();

  const events: MatchedEventDetail[] = state ? state["matchedEvents"] : [];

  const expandedView = events?.length === 2;

  const onJoinClickHandler = (eventId) => {
    const url = `/event/${eventId}`;
    logClick({
      action: `join_show_from_multi_lobby`,
      eventId: eventId,
    });
    history.push(url);
  };

  const onֿֿDiffShowClickHandler = (eventIds: string[]) => {
    logClick({
      action: `different_show_from_multi_lobby`,
      eventId: eventIds?.join(","),
    });
    setAllowLocationSearch(false);
    history.push("/");
  };

  if (!events?.length) {
    setAllowLocationSearch(false);
    return <Redirect to="/" />;
  }

  return (
    <Wrapper>
      <Header />
      <Content flex="1 1 auto">
        <H3>{events?.length} Shows Found Nearby</H3>
        <H4>Please select your show...</H4>
        {events?.map((event) => {
          const startsAt = DateTime.fromMillis(event.startsAt);
          return (
            <ShowCard key={event.id} expandedView={expandedView}>
              <FlexRow yCenter style={{ overflow: "hidden" }}>
                <EventImage src={event.image} />
                <FlexColumn
                  yStart
                  style={{ width: "100%", overflow: "hidden" }}
                >
                  <H4>
                    <Ellipses>{event.artistName}</Ellipses>
                  </H4>
                  <Small>
                    <IconLocation style={{ color: "#E45C52" }} />
                    <Ellipses>{event.venue}</Ellipses>
                  </Small>
                  <Small>
                    <IconCalendar style={{ color: "#1B0076" }} />
                    {startsAt?.toFormat("cccc, LLL d")}
                    {getDateOrdinal(startsAt?.day)} at{" "}
                    {startsAt?.toFormat("h:mm")}{" "}
                    {startsAt?.toFormat("a")?.toLowerCase()}{" "}
                    {startsAt?.toFormat("ZZZZ")}
                  </Small>
                </FlexColumn>
              </FlexRow>
              <Button onClick={() => onJoinClickHandler(event.id)}>
                {lobby?.joinButton}
              </Button>
            </ShowCard>
          );
        })}
        <ButtonSecondary
          onClick={() => onֿֿDiffShowClickHandler(events?.map(({ id }) => id))}
        >
          {lobby?.cancelButton}
        </ButtonSecondary>
      </Content>
      <FloatContainer>
        <Small>
          By joining any show, you agree to our{" "}
          <Link
            onClick={() => window.open(termsOfUseUrl || "/terms", "_blank")}
          >
            terms of use
          </Link>
        </Small>
      </FloatContainer>
      <Footer style={{ width: "100%" }} />
    </Wrapper>
  );
};

const Ellipses = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FloatContainer = styled(FlexColumn)`
  background-color: ${(props) => props.theme.branding.lobby?.colors?.page};
  padding: 16px 24px 24px;
  height: 54px;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 150;

  ${Small} {
    color: ${(props) => props.theme.branding.lobby.colors.legal};
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    ${Link} {
      font-size: 12px;
      line-height: 18px;
      margin-left: 1px;
    }
  }
`;

const EventImage = styled.img`
  border-radius: 4px;
  object-fit: cover;
  object-position: center top;
  width: 72px;
  height: 72px;
  margin-right: 16px;
  flex-shrink: 0;
`;

const Footer = styled(FooterBase)`
  background-color: ${(props) => props.theme.branding.lobby?.colors?.page};
`;

const Wrapper = styled(FlexColumn)`
  flex-grow: 1;
  background-color: ${(props) => props.theme.branding.lobby?.colors?.page};
  align-items: center;
`;

const ShowCard = styled(FlexColumn)<{ expandedView?: boolean }>`
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  padding: 12px;
  overflow: hidden;

  ${H4} {
    color: ${(props) => props.theme.branding.lobby.colors.artist};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  ${Small} {
    color: ${(props) => props.theme.branding.lobby.colors.date};
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    line-height: 18px;
    margin-top: 2px;
    svg {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin-right: 6px;
      position: relative;
      top: 1px;
    }
  }

  ${Button} {
    margin-top: 16px;
    width: 100%;
  }

  & + & {
    margin-top: 24px;
  }

  ${(props) =>
    props.expandedView &&
    css`
      ${EventImage} {
        width: 33%;
        height: auto;
      }
      ${H4} {
        margin-bottom: 6px;
      }
      ${Small} { {
        margin-top: 4px;
      }
      ${Button} {
        margin-top: 20px;
      }
    `};
`;

const Content = styled(FlexColumn)`
  padding: 16px 24px 16px;
  width: 100%;
  position: relative;
  z-index: 2;
  & > ${H3} {
    color: ${(props) => props.theme.branding.lobby.colors.artist};
    font-family: ${(props) => props.theme.branding.bodyFont};
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  }
  & > ${H4} {
    color: #666666;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 16px;
  }
  ${ButtonSecondary} {
    margin-top: 24px;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    max-width: 392px;
  }
`;
