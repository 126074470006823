const PrimaryColorWeightList = [100, 80, 60, 40, 20, 10] as const;
type PrimaryColorWeights = typeof PrimaryColorWeightList[number];

const BlackWeightList = [100, 80, 70, 60, 40, 20] as const;
type BlackColorWeights = typeof BlackWeightList[number];

const GrayWeightList = [4, 3, 2, 1, 0] as const;
type GrayColorWeights = typeof GrayWeightList[number];

type PrimaryColorProp = {
  [key in PrimaryColorWeights]: string;
};
type BlackColorProp = {
  [key in BlackColorWeights]: string;
};
type GrayColorProp = {
  [key in GrayColorWeights]: string;
};
export type Colors = {
  black?: BlackColorProp;
  gray?: GrayColorProp;
  navy?: PrimaryColorProp;
  red?: PrimaryColorProp;
  blue?: PrimaryColorProp;
  yellow?: PrimaryColorProp;
  green?: PrimaryColorProp;
};

const navy = {
  100: "#1b0076",
  80: "#493391",
  60: "#7666ad",
  40: "#a499c8",
  20: "#d1cce4",
  10: "#e8e5f1",
};

const red = {
  100: "#e71e3d",
  80: "#ec4b64",
  60: "#f1788b",
  40: "#f5a5b1",
  20: "#fad2d8",
  10: "#fce8eb",
};

const blue = {
  100: "#057bad",
  80: "#3795bd",
  60: "#69b0ce",
  40: "#9bcade",
  20: "#cde5ef",
  10: "#e6f1f6",
};

const yellow = {
  100: "#ffcf21",
  80: "#ffd94d",
  60: "#ffe27a",
  40: "#ffeca6",
  20: "#fff5d3",
  10: "#fffae8",
};

const green = {
  100: "#00bd68",
  80: "#33ca86",
  60: "#66d7a4",
  40: "#99e4c3",
  20: "#ccf2e1",
  10: "#e5f8ef",
};

const gray = {
  0: "#FFFFFF",
  1: "#f7fafc",
  2: "#f2f5f7",
  3: "#e6e9eb",
  4: "#dcdfe0",
};

const black = {
  100: "#000000",
  80: "#333333",
  70: "#4c4c4c",
  60: "#666666",
  40: "#999999",
  20: "#cccccc",
};

export const colors: Colors = {
  gray,
  black,
  navy,
  red,
  blue,
  yellow,
  green,
};
