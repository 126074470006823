import { Routes } from "Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import {
  AnalyticsProvider,
  AppProvider,
  AuthProvider,
  BrandingProvider,
  LocationProvider,
  SearchProvider,
} from "Context";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { UserProvider } from "auth";

function App() {
  return (
    <Router>
      <UserProvider blockRendering={false}>
        <AuthProvider>
          <HelmetProvider>
            <BrandingProvider>
              <AppProvider>
                <LocationProvider>
                  <AnalyticsProvider>
                    <SearchProvider>
                      <Routes />
                      <ToastContainer />
                    </SearchProvider>
                  </AnalyticsProvider>
                </LocationProvider>
              </AppProvider>
            </BrandingProvider>
          </HelmetProvider>
        </AuthProvider>
      </UserProvider>
    </Router>
  );
}

export default App;
