import { useState, useEffect, ReactNode } from "react";
import styled, { css } from "styled-components";
import { Input, TextField } from "notes";

const NO_RESULTS_TEXT = "No options found...";
interface AutocompleteProps {
  onChange: (v: any) => void;
  search: (searchTerm: any) => Promise<{}[]>;
  selected?: any;
  options?: any;
  Loading?: () => JSX.Element;
  Result?: any;
  leftIcon?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  openOnFocus?: boolean;
}

export const Autocomplete = ({
  onChange,
  search,
  selected,
  options,
  Loading = LoadingDefault,
  Result = Item,
  disabled,
  openOnFocus,
  ...props
}: AutocompleteProps) => {
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = async (value, e) => {
    const newValue = e.currentTarget.value;
    if (newValue) {
      setShowOptions(true);
    }
    onChange({});
    setValue(newValue);
  };

  useEffect(() => {
    let cancelled = false;
    if (value) {
      (async () => {
        setLoading(true);
        const results: any = await search(value);
        if (!cancelled) {
          setLoading(false);
          setResults(results);
        }
      })();
    } else {
      setLoading(false);
      setResults([]);
      setShowOptions(false);
    }
    return () => {
      cancelled = true;
    };
  }, [value]);

  useEffect(() => {
    if (openOnFocus && !!options?.length) {
      if (isFocused) {
        setShowOptions(true);
      } else {
        setShowOptions(false);
      }
    }
  }, [isFocused, openOnFocus]);

  const onClick = (e, result) => {
    onChange(result);
    setValue("");
    setResults([]);
    setShowOptions(false);
  };

  let optionList;
  if (showOptions) {
    if (results?.length || (options?.length && !value)) {
      const renderOptions = results?.length ? results : options || [];
      optionList = renderOptions.map((result, index) => {
        return (
          <Result
            key={`ac-option-${index}`}
            onClick={(e) => onClick(e, result)}
            onKeyDown={(e) => {
              e.which === 13 && onClick(e, result);
            }}
            result={result}
            tabIndex={0}
            data-javelin-name={`change-location-select`}
            data-javelin-meta={JSON.stringify(result)}
          >
            {result}
          </Result>
        );
      });
    } else {
      optionList = (
        <Result result={{ text: NO_RESULTS_TEXT }}>{NO_RESULTS_TEXT}</Result>
      );
    }
  }

  return (
    <FieldWrapper>
      <InputStyling open={showOptions} disabled={disabled}>
        <Input
          InternalField={StyledInput}
          onChange={handleChange}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...props}
        />
      </InputStyling>
      {loading ? (
        <StyledDropdown>
          <Loading />
        </StyledDropdown>
      ) : (
        !!optionList && <StyledDropdown>{optionList}</StyledDropdown>
      )}
    </FieldWrapper>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  label > & {
    margin-top: 9px;
  }
`;

const InputStyling = styled.div<{ open?: boolean; disabled?: boolean }>`
  & > div {
    border: 1px solid #cccccc;
    ${(props) =>
      props.open &&
      css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-color: #e6e9eb;
        box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
        z-index: 4;
      `};

    ${(props) =>
      props.disabled &&
      css`
        background-color: #ffffff;
        border-color: #e6e9eb;
        pointer-events: none;
      `};

    & > div:first-child {
      height: 44px;
    }
  }
`;

const StyledInput = styled(TextField).attrs(() => ({ as: "input" }))`
  position: relative;
  z-index: 1;
  border: none;
  border-radius: 0;
  height: 44px;
  &:focus {
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
  }
  ${(props) => props.disabled && "pointer-events: none;"};
`;

const StyledDropdown = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 0;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24);
  max-height: 224px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
`;

const Item = styled.a<{ selected?: boolean }>`
  background-color: ${(props) =>
    props.selected ? props.theme.colors.selectItemHover : "#FFFFFF"};
  border-bottom: 1px solid #e6e9eb;
  color: ${(props) => props.theme.colors.inputText};
  cursor: pointer;
  display: block;
  ${(props) => props.theme.fonts.default}
  font-weight: ${(props) => (props.selected ? 400 : 300)};
  line-height: 22px;
  position: relative;
  padding: 17px 12px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.selectItemHover};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const LoadingDefault = () => {
  return <Item>Loading...</Item>;
};
