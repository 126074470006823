import styled from "styled-components";
import { FlexColumn, Modal, H4, Button, ModalContainer } from "notes";
import { updateFirestoreDoc } from "firebase-internal";
import { Timestamp } from "firebase/firestore";
import { useArtistModalContext } from ".";
import { useHistory } from "react-router-dom";

export const ModalEndEvent = ({ open }) => {
  return (
    <StyledModal size="small" open={open}>
      <EndEvent />
    </StyledModal>
  );
};

export const EndEvent = () => {
  const { closeModal, modal } = useArtistModalContext();
  const eventId = modal?.endEvent;
  const history = useHistory();
  const handleSubmit = async () => {
    if (!!eventId) {
      await updateFirestoreDoc(`setlive_events/${eventId}`, {
        endedAt: Timestamp.now(),
      });
    }
    closeModal();
    history.push("/artist");
  };

  return (
    <Wrapper>
      <H4>End Show Confirmation</H4>
      <Actions xEnd>
        <EndButton onClick={handleSubmit}>End Show</EndButton>
        <Button type="tertiary" onClick={closeModal}>
          Cancel
        </Button>
      </Actions>
    </Wrapper>
  );
};

const StyledModal = styled(Modal)`
  ${ModalContainer} {
    border-radius: 8px;
    padding: 24px;
    width: 300px;
    min-height: 0;
    & > svg {
      display: none;
    }
  }
`;

const Actions = styled(FlexColumn)`
  padding: 24px 0 0 0;
  button {
    width: 100%;
  }
`;

const Wrapper = styled(FlexColumn)`
  ${H4} {
    color: ${(props) => props.theme.palette.black};
    font-weight: 700;
    text-align: center;
  }
`;

const EndButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.red.primary};
  margin-bottom: 8px;
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${(props) => props.theme.palette.red.darker};
  }
`;
